.Button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  font-family: HK Grotesk;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-self: center;
  align-self: center;
  align-items: center;
  text-align: center;
  /* letter-spacing: 0.02em; */
  color: #000000;
  text-decoration: none;
}

.ButtonIcon {
  transition: opacity 0.3s;
  opacity: 1;
}
.ButtonIcon.hide {
  opacity: 0;
}

.button-container {
  flex-direction: row;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #fcf9f1;
  padding: 8px;
  border: 2px solid black;
}
