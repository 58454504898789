.title-text {
  font-family: Passion One;
  font-weight: normal;
  font-size: 80px;
  align-self: center;
  text-align: center;
  opacity: 1;
  letter-spacing: 12;
}

.title-wrapper {
  padding-bottom: 20px;
}
