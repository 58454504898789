/* Rectangle 17 */
.Divider {
  /* position: absolute; */
  width: 100%;
  height: 1px;

  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    #000000 50%,
    rgba(0, 0, 0, 0) 100%
  );
  transform: matrix(1, 0, 0, -1, 0, 0);
}
