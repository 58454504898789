.Card {
  width: 120px;
  min-width: 200px;
  height: 200px;
  border: 1px solid #000000;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  transition: opacity 200ms ease-out;
}

.Card.disabled {
  opacity: 0.25;
  cursor: auto;
}

.CardContainer {
  margin: 20px;
  /* Color is decided inline */
  border: 2px solid transparent;
  box-sizing: border-box;
  padding: 10px;
  transition: border 200ms ease-out;
}

.CardContainer.disabled:hover {
  border-color: transparent;
}

.join-text {
  font-family: HK Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  opacity: 1;
  align-self: flex-end;
  justify-self: center;
  width: 100%;
  margin: 0;
  padding-bottom: 10;
  transition: opacity 0.2s;
}

.join-text.hide {
  opacity: 0;
}
