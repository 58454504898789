.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  /* background-color: #fcf9f1; */
}

.content {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 768px) {
  .content.hex-content {
    justify-content: flex-start;
    padding-top: 120px;
  }
}

@media (max-width: 480px) {
  .content.hex-content {
    justify-content: flex-start;
    padding-top: 80px;
  }
}

/* Mask Group */

.Mask {
  width: 593.35px;
  height: 91.28px;
  opacity: 0.25;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

/* Ellipse 16 */

.HalfEllipseUp {
  width: 100%;
  height: 100%;
  opacity: 25%;
  background: radial-gradient(
    50% 50% at bottom center,
    #c0b59f 0%,
    rgba(73, 35, 0, 0) 100%
  );
}

.HalfEllipseDown {
  width: 100%;
  height: 100%;
  opacity: 25%;
  background: radial-gradient(
    50% 50% at top center,
    #c0b59f 0%,
    rgba(73, 35, 0, 0) 100%
  );
}

/* Rectangle 18 */

.Rectangle18 {
  position: absolute;
  width: 1035.5px;
  height: 221.08px;
  left: 444.72px;
  top: 79.67px;

  background: #c0b59f;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

/* HEXAGON */

.hex:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 120px solid #fcf9f1;
  border-left: 208px solid transparent;
  border-right: 208px solid transparent;
  position: absolute;
  top: -120px;
}

.hex.background:before {
  border-bottom-color: black;
}

.hex {
  width: 416px;
  height: 240px;
  background-color: #fcf9f1;
  transform: scale(1, 1);
}

/* If more menu buttons, this will probably be needed. */
/* @media (max-width: 900px) {
    .hex {
        left: 80px
     }
} */
@media (max-width: 768px) {
  .hex {
    left: unset;
  }
}

.hex.background {
  background-color: black;
  transform: scale(1.02, 1.02);
}

@media (max-width: 768px) {
  .hex.background {
    transform: scale(0.82, 0.82);
  }

  .hex {
    transform: scale(0.8, 0.8);
  }
}

@media (max-width: 480px) {
  .hex.background {
    transform: scale(0.67, 0.67);
  }

  .hex {
    transform: scale(0.65, 0.65);
  }
}

.hex:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -120px;
  border-top: 120px solid #fcf9f1;
  border-left: 208px solid transparent;
  border-right: 208px solid transparent;
}

.hex.background:after {
  border-top-color: black;
}

.hex-content-container {
  min-width: 416px;
}

.side-bar-container {
  z-index: 3;
  position: absolute;
  right: 0;
  height: 100vh;
  display: flex;
  max-width: 350px;
}

@media (max-width: 768px) {
  .side-bar-container {
    width: 100%;
    margin-top: 320px;
    height: unset;
    max-width: unset;
  }
}

@media (max-width: 480px) {
  .side-bar-container {
    width: 100vw;
    margin-top: 280px;
  }
}

.side-bar-content {
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .side-bar-content {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
