.PrimaryInput {
  font-family: HK Grotesk;
  font-size: 24px;
  font-style: normal;
  line-height: 31px;
  letter-spacing: 0.25em;
  text-align: center;
  background-color: transparent;
  border: none;
  border: 1.5px solid #000000;
  border-radius: 2px;
  padding-left: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 300px;
  opacity: 1;
}

.PrimaryInput::placeholder {
  letter-spacing: 0;
  color: white;
  opacity: 1;
}

.dark.PrimaryInput::placeholder {
  color: gray;
}

.errorMessage {
  font-family: HK Grotesk;
  font-weight: 800;
  font-size: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0px;
  color: #000000;
  margin: 0;
  opacity: 1;
  color: red;
}
